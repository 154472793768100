<template>
  <div>
    <h1>You must be logged in to add a holiday</h1>
  </div>
</template>

<script>
export default{
  data(){
    return{}
  },
  methods:{
  }
}
</script>

<style scoped>
</style>
